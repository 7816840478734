// Notifications

// Example usage:
// $.notify({ type: "success", title: "Optional title", message: "Some descriptive message" });

$(function() {
    $.notify = function(options) {
        // Skip showing the toast, if no message set
        if( ! options.message ) {
            return;
        }

        options.autohide = (!options.autohide || options.autohide == 'True')

        var data = $.extend({
            id: $.generateHash(8),          // Used to identify the current toast
            delay: 4000,                    // Time before autohide, in ms
            type: 'info',                   // Used to style the notification, e.g. info, success, danger
            autohide: options.autohide,     // Set to false to prevent auto hiding
            position: 'top center'          // The toast position on screen
        }, options);

        // Track notifications and whether the user has a CSRF token set, required to POST data to our server.
        var has_csrf = (Cookies.get('csrftoken')) ? 1 : 0;
        var msg = (data.title) ? data.title.replace(/<[^>]*>?/gm, '') + ' - ' + data.message.replace(/<[^>]*>?/gm, '') : data.message.replace(/<[^>]*>?/gm, '');

        ga(
            'send',
            'event',
            'Notification',
            data.type,
            msg,
            has_csrf
        );

        var html = templates.render('partials/_notify.html', data);

        if( ! $('#notify').length ) {
            $('<div/>', {
                id: 'notify',
                class: data.position
            }).appendTo('body');
        }

        $(html).appendTo('#notify');

        // Cleanup existing toasts from the DOM and remove any transition issues
        // with displaying elements.
        $('.toast').addClass('d-none').toast('hide');
        $('.toast.hide').remove();
        $('#toast-' + data.id).removeClass('d-none').toast('show');
    };
});


// Generate random alphanumeric string
$(function() {
    $.generateHash = function(length) {
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        length = length || 8;

        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    };
});


// Template filters for Nunjucks
window.filters = {
    dateformat: function(date, options) {
        return dayjs(date).format(options.format || 'MMM Do, YYYY');
    },

    priceformat: function(num, currency_code) {
        currency_code = currency_code || 'USD';

        currency_symbols = {
            'USD': '$',
            'EUR': '&euro;'
        };

        symbol = currency_symbols[currency_code] || currency_code;

        return symbol + num.toFixed(2);
    },

    filesizeformat: function(input, binary) {
        var base = binary ? 1024 : 1000;
    	var bytes = parseFloat(input);
    	var units = [
    		'Bytes',
    		(binary ? 'KiB' : 'kB'),
    		(binary ? 'MiB' : 'MB'),
    		(binary ? 'GiB' : 'GB'),
    		(binary ? 'TiB' : 'TB'),
    		(binary ? 'PiB' : 'PB'),
    		(binary ? 'EiB' : 'EB'),
    		(binary ? 'ZiB' : 'ZB'),
    		(binary ? 'YiB' : 'YB')
    	];

    	if (bytes === 1) {
    		return '1 Byte';
    	} else if (bytes < base) {
    		return bytes + ' Bytes';
    	} else {
    		return units.reduce(function (match, unit, index) {
    			var size = Math.pow(base, index);
    			if (bytes >= size) {
    				return (bytes/size).toFixed(1) + ' ' + unit;
    			}
    			return match;
    		});
    	}
    },

    intcomma: function(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    sum: function(items, property) {
        var sum = 0;

        items.forEach(function(item) {
            sum += item[property.attribute];
        });

        return sum;
    },

    title: function(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    pluralize: function(input) {
        return input != 1 ? 's' : '';
    }
};


// Register the filters
$(function () {
    window.templates = new nunjucks.Environment();

    $.each(window.filters, function(key, value) {
        templates.addFilter(key, value, true);
    });
});


$(function() {
    /**
     * Test if a method is exempt CSRF protection.
     *
     * @param {string} method Request method.
     * @returns true if a method is exempt from CSRF protection, otherwise
     * false.
     */
    function csrfSafeMethod(method) {
        // these HTTP methods do not require CSRF protection
        return (/^(GET|HEAD|OPTIONS|TRACE)$/i.test(method));
    }


    /**
     * Test if a URL is on the same origin.
     *
     * @param {string} url URL to test.
     * @returns true if the URL is on the same origin, otherwise false.
     */
    function sameOrigin(url) {
        // test that a given url is a same-origin URL
        // url could be relative or scheme relative or absolute
        var host = document.location.host; // host + port
        var protocol = document.location.protocol;
        var sr_origin = '//' + host;
        var origin = protocol + sr_origin;
        // Allow absolute or scheme relative URLs to same origin
        return (url == origin || url.slice(0, origin.length + 1) == origin + '/') ||
            (url == sr_origin || url.slice(0, sr_origin.length + 1) == sr_origin + '/') ||
            // or any other URL that isn't scheme relative or absolute i.e relative.
            !(/^(\/\/|http:|https:).*/.test(url));
    }

    // Set up AJAX.
    $.ajaxSetup({
        beforeSend: function(xhr, settings) {
            if ((!csrfSafeMethod(settings.type)) &&
                (sameOrigin(settings.url))) {
                // Send the token to same-origin, relative URLs only.
                // Send the token only if the method warrants CSRF protection
                // Using the CSRFToken value acquired earlier
                xhr.setRequestHeader("X-CSRFToken", Cookies.get('csrftoken'));
            }
        }
    });


    // Log AJAX errors to Sentry
    $(document).ajaxError(function(event, jqXHR, ajaxSettings, thrownError) {
        // Skip logging AJAX aborts
        if( thrownError === 'abort' || jqXHR.status < 500) return;

        var response = jqXHR.responseJSON || jqXHR.responseText;
        var tags = {errorOrigin: 'AJAX'};
        var msg = 'Unknown error occurred';
        var exception = 'No exception data';

        if (response) {
            msg = response.message || thrownError || jqXHR.statusText || msg;
            exception = response.exception || exception;

            if (response.code)
                tags.code = response.code;
        } else {
            response = 'No response data';
        }

        var error = new Error(msg);

        Sentry.captureException(error, {
            extra: {
                type: ajaxSettings.type,
                url: ajaxSettings.url,
                data: ajaxSettings.data,
                status: jqXHR.status,
                exception: exception,
                response: response
            },
            tags: tags
        });
    });
});


$(function() {
    $('.autocomplete').each(function() {
        var element = $(this);
        var form = element.parents('form');
        var elementHeight = element.outerHeight();

        // Search history store
        var History = {
            settings: {
                expires: 7,
                key: element.data('history')
            },
            get: function(input) {
                var history = [];

                if (this.settings.key) {
                    var storage = localStorage.getItem(this.settings.key);

                    if (storage) {
                        history = JSON.parse(storage);
                    }
                }

                if (input && input.length > 0) {
                    history = _.filter(history, function(item) {
                        return item.value.lastIndexOf(input.toLowerCase(), 0) === 0;
                    });
                }

                return history;
            },
            set: function(data) {
                if (this.settings.key) {
                    localStorage.setItem(this.settings.key, JSON.stringify(data));
                }
            }
        };


        // The suggestions dropdown
        var Suggestions = {
            url: element.data('url'),
            render: function(data) {
                this.remove();

                if (data.length > 0) {
                    var html = templates.render('search/_suggestions.html', {
                        input: $.trim(element.val()).toLowerCase(),
                        suggestions: data.slice(0,6)
                    });

                    form.addClass('has-dropdown').append(html);
                    form.find('.suggestions').css('top', elementHeight);
                }
            },
            remove: function() {
                form.removeClass('has-dropdown').find('.suggestions').remove();
            }
        };


        // Bind the focus states
        element.on('focus blur', function(e) {
            var is_focused = e.type == 'focus' || e.type == 'click';
            var target = $(e.relatedTarget);

            // Cancel the event to allow interaction with the suggestions
            if (target.parents('form').is(form)) {
                return false;
            }

            form.toggleClass('focus', is_focused);

            if (!is_focused) {
                Suggestions.remove();
            }
        });


        // Show the search history on click
        element.on('click', function() {
            var value = $.trim(element.val());
            var history = History.get(value);

            Suggestions.render(history);
        });


        // Un-select the suggestions when hovering an item
        form.on('mouseenter', '.suggestions-list .list-group-item-action', function(e) {
            $('.suggestions-list .list-group-item-action').removeClass('selected');
        });


        // Enter key confirms the selected suggestion if available
        element.on('keydown', function(e) {
            if (e.which == 13) {
                var suggestion = form.find('.suggestions-list .selected');

                if (suggestion.length > 0) {
                    suggestion.trigger('click');

                    // Prevent the form submission
                    e.preventDefault();

                    // Stop the event propagation
                    e.stopImmediatePropagation();
                }
            }
        });


        // Remove the prefix filters on backspace
        element.on('keydown', function(e) {
            if (e.which == 8) {
                var value = $.trim(element.val());

                if (!value.length) {
                    form.find('.input-group-prepend.filter-list [data-filter]:last').trigger('click');
                }
            }
        });


        // Keyboard dropdown navigation
        element.on('keyup', function(e) {
            // Up and down keys
            if (e.which == 38 || e.which == 40) {
                var dropdown = form.find('.suggestions');

                if (dropdown.length > 0) {
                    var suggestions = dropdown.find('[data-value]');
                    var selected = suggestions.filter('.selected');
                    var target;

                    // Key down
                    if (e.which == 38) {
                        target = (selected.length > 0 && selected.prev().length > 0) ? selected.prev() : suggestions.last();
                    }

                    // Key up
                    if (e.which == 40) {
                        target = (selected.length > 0 && selected.next().length > 0) ? selected.next() : suggestions.first();
                    }

                    target.addClass('selected');
                    selected.removeClass('selected');
                } else {
                    element.trigger('click');
                }

                // Stop the propagation to avoid fetching new results
                e.stopImmediatePropagation();
            }
        });


        // Prevent the input cursor from jumping on up/down keys
        element.on('keydown keyup', function(e) {
            if(e.which == 38 || e.which == 40){
                e.preventDefault();
            }
        });


        // Fetch suggestions, throttled to reduce requests.
        element.on('keyup', _.throttle(function(e) {
            var value = $.trim(element.val()).toLowerCase();
            var history = History.get(value);

            // Ignore the meta keys
            if ($.inArray(e.key, ['Shift', 'Alt', 'Control', 'Meta', 'PageDown', 'PageUp', 'Home', 'End']) >= 0) {
                return;
            }

            if (Suggestions.url && value.length) {
                // Perform the search only if the value changes
                if (value == element.data('value')) {
                    return;
                }

                $.getJSON(Suggestions.url, {
                    q: value
                }, function(response) {
                    var data = $.map(response, function(item) {
                        // Format flat string array responses
                        if (typeof item == 'string') {
                            item = {
                                q: item,
                                value: item
                            };
                        }

                        return item;
                    });

                    // Unite the history and response data
                    data = _.union(history, data);

                    // Ensure the suggestions are unique
                    data = _.uniq(data, function(item) {
                        return item.value;
                    });

                    Suggestions.render(data.slice(0,8));
                });
            } else {
                Suggestions.render(history);
            }

            // Update the value variable for later comparison
            element.data('value', value);
        }, 200));


        // Mark the form as submitted and remove the suggestions
        form.on('submit', function() {
            form.addClass('submitted');
            Suggestions.remove();
        });


        // Save the search terms history
        form.on('submit', function(e) {
            var value = $.trim(element.val());
            var history = History.get();

            // Skip saving short inputs
            if (value.length < 2) {
                return;
            }

            // Add search term in front of the array
            history.unshift({
                type: 'history',
                value: value
            });

            // Limit the history length
            history = history.slice(0, 12);

            // Ensure the history terms are unique by value
            history = _.uniq(history, function(item) {
                return item.value;
            });

            History.set(history);
        });


        // Remove search history items
        form.on('click', '[data-remove-history]', function(e) {
            var item = $(this);
            var value = item.data('remove-history');
            var history = History.get();

            if (history) {
                history = _.filter(history, function(item) {
                    return item.value != value;
                });

                item.parents('.list-group-item').remove();

                History.set(history);

                element.trigger('focus').trigger('click');
            }

            return false;
        });


        // Submit the form with suggestion
        form.on('click', '[data-value]', function(e) {
            e.preventDefault();

            var value = $(this).data('value');

            form.find('input[name="q"]').val(value);
            form.submit();
        });
    });

    // Clean up old cookie (replaced by localStorage)
    Cookies.remove('search_history');
});

$(function () {
    // Form serialization support for names with hyphen(-)
    $.extend(FormSerializer.patterns, {
        validate: /^[a-z][a-z0-9_-]*(?:\[(?:\d*|[a-z0-9_-]+)\])*$/i,
        key: /[a-z0-9_-]+|(?=\[\])/gi,
        named: /^[a-z0-9_-]+$/i
    });

    $.fn.findIncludeRoot = function(selector) {
        // A find function that includes classes on root/selector element
        return this.filter(selector).add(this.find(selector));
    }


    // Toggle element visibility
    $(document).on('click', '[data-toggle="toggle"]', function(e) {
        var element = $(this);
        var target = element.data('target') || element.attr('href');

        // Skip preventing the default on inputs
        if (!element.is(':input')) {
            e.preventDefault();
        }

        $(target).toggleClass('d-none');
    });


    // Enable tooltips
    $('[data-toggle="tooltip"]').tooltip();


    // Enable popovers
    $('[data-toggle="popover"]').popover();


    // Hide a popover when an element within is clicked
    $(document).on('click', '.popover [data-dismiss="popover"]', function() {
        $(this).parents('.popover').popover('hide');
    });


    // License helper popover
    $(document).on('mouseenter', '[data-license]', function() {
        var element = $(this);

        // Skip on small screens
        if( $(window).width() < 600 ) return;

        var timer = setTimeout(function() {
            var html = templates.render('licenses/_summary.html', {
                name: element.data('license')
            });

            if( html.length > 0 ) {
                // Render the description popover, if avalable
                element.popover({
                    content: html,
                    html: true,
                    sanitize: false,
                    delay: {
                        "show": 1000
                    },
                    trigger: 'hover',
                    placement: 'bottom'
                }).popover('show');
            } else {
                // Fallback to tooltip with the full license name
                element.tooltip({
                    title: "Licensed under " + element.data('license'),
                    placement: 'bottom'
                }).tooltip('show');
            }
        }, 500);

        element.on('mouseleave', function() {
            clearTimeout(timer);
        });
    });


    // Requires confirmation
    $(document).on('click', '[data-confirm]', function(e) {
        var confirmed = confirm($(this).data('confirm'));

        if( ! confirmed ) {
            e.stopImmediatePropagation();
        }

        return confirmed;
    });


    // Hide tooltips on click
    $(document).on('click', '[data-toggle="tooltip"]', function() {
        $(this).tooltip('hide');
    });


    // Autofocus within collapsed element
    $(document).on('shown.bs.collapse', '.collapse', function () {
        $(this).find('[autofocus]').trigger('focus');
    });


    // Background picker
    $('.background-picker').on('click', 'a', function(e) {
        e.preventDefault();

        var element = $(this);
        var parent = element.parents('.background-picker');
        var color = element.data('value');

        parent.find('.active').removeClass('active');
        element.addClass('active');

        $('body').removeClass('preview-white preview-black preview-gray preview-transparent').addClass('preview-' + color);

        // Save the background preferences
        Cookies.set('icon-preview-color', color);
    });


    // Shut down Intercom on logout
    $(document).on('click', '[href="/user/logout"]', function() {
        Intercom('shutdown');
    });


    // Flash messages
    if (window.nf_messages) {
        // From the backend (nf_messages)
        $.each(window.nf_messages, function(index, notification) {
            $.notify(notification);
        });
    } else if( Cookies.getJSON('flash-message') ) {
        // Form cookies
        $.notify(Cookies.getJSON('flash-message'));
        Cookies.remove('flash-message');
    }


    // Inline element tracking
    // Example: data-tracking="Category|Action|Label|Value"
    $(document).on('click', '[data-tracking]', function(e) {
    
        var data = $(this).data('tracking').split('|');
        
        if(data[0] === 'Freepik') {
            e.preventDefault();
            var location = 'IDP';
            if(document.body.classList.contains('homepage')) {
                location = 'HOME';
            }
            if( document.querySelector('#search-results')) {
                location = 'SRP';
            }
            
            var url = new URL(this.href);
            url.searchParams.set('utm_source', 'iconfinder');
            url.searchParams.set('utm_medium', 'affiliate');
            url.searchParams.set('utm_campaign', location);
            window.open(url, '_blank');
        } else {
            if( data.length < 2 ) {
                return;
            }
    
            ga('send', {
                hitType: 'event',
                eventCategory: data[0],
                eventAction: data[1],
                eventLabel: data[2] || null,
                eventValue: data[3] || null
            });
        }


    });


    // Custom tracking from URL params
    if( $.url().param('trackevent') == 'true' ) {
        var data = $.url().param();

        ga('send', {
            hitType: 'event',
            eventCategory: data.event_category,
            eventAction: data.event_action,
            eventLabel: data.event_label,
            eventValue: data.event_value
        });
    }


    // Inline element hotkey binding
    $.fn.hotkey = function() {
        return this.each(function() {
            var element = $(this);
            var key = element.data('hotkey');

            $(document).on('keyup', null, String(key), function() {
                element.trigger('focus').trigger('click');

                if (element.prop('tagName').toLowerCase() === 'a') {
                    window.location = element.attr('href');
                }
            });
        });
    };

    $('[data-hotkey]').hotkey();


    // Group checkbox toggle
    $(document).on('change', 'input[data-group]', function() {
        var element = $(this);
        var group = element.data('group');
        var group_inputs = $('input[data-group="'+ group +'"]');

        if (element.data('toggle') == 'checkall') {
            group_inputs.prop('checked', element.is(':checked'));
        }

        var has_selected = group_inputs.filter(':checked').length === 0;

        $('button[data-group="'+ group +'"]').attr('disabled', has_selected);
    });


    // Select dropdown navigation
    $(document).on('change', '.select-navigation', function() {
        var value = $(this).val();

        window.location.replace(value);
    });


    // Overwrite the search form URL with a prefix
    $('.search-form').on('submit', function() {
        var form = $(this);
        var search_url_prefix = form.find('[data-search_url]');

        if (search_url_prefix.length > 0) {
            var search_url = search_url_prefix.data('search_url');
            form.attr('action', search_url);
        }
    });


    // Remove suggestions if the users clicks the index dropdown.
    $('.search-form .index-selector').on('shown.bs.dropdown hidden.bs.dropdown', function() {
        $('.search-form').removeClass('has-dropdown').find('.suggestions').remove();
    });


    // Update the index dropdown state
    $('.search-form .index-selector a').on('click', function(e) {
        e.preventDefault();

        var element = $(this);
        var action = element.attr('href');
        var label = element.text();
        var search_forms = $('.search-form');
        var index_selectors = search_forms.find('.index-selector');

        // Update dropdown item states
        index_selectors.find('.dropdown-item.active').removeClass('active');
        index_selectors.find('.dropdown-item[data-action="'+ action +'"]').addClass('active');
        index_selectors.find('button span').text(label);

        // Update the search forms URL
        search_forms.attr('action', action);
    });


    // Remove search prefixes
    $('.filter-list').on('click', '[data-filter]', function(e) {
        e.preventDefault();

        var element = $(this);
        var filter = element.data('filter');
        var search_forms = $('.search-form');

        search_forms.find('[name="'+ filter +'"]').remove();
        element.tooltip('dispose').remove();
        Cookies.remove(filter);

        if (!search_forms.find('.filter-list [data-filter]').length) {
            // Show the search forms index selector
            search_forms.find('.index-selector').removeClass('d-none');

            // Update the search forms placeholders
            search_forms.find('input[name="q"]').each(function() {
                var element = $(this);
                var placeholder = element.data('placeholder');

                element.attr('placeholder', placeholder);
            });
        }
    });


    // Toggle Pro plan boxes annual billing
    $('#billing-interval input').on('change', function() {
        $('[data-interval]').toggleClass('d-none');
    });


    // Confirm payment intent
    $.confirmPayment = function(client_secret, callback) {
        callback = callback || $.noop();

        // Display loading overlay
        $('body').loading(true, {
            title: 'Authenticating',
            description: 'Please wait...'
        });

        // Track confirmation request
        ga('send', 'event', 'Commerce', 'Payment confirmation (3D secure)', 'Requested');

        stripe.handleCardPayment(client_secret).then(function(result) {
            $('body').loading(false);

            if( result.error ) {
                // If something went wrong, remove credit card if wasn't attached to the customer
                if( result.error ) {
                    $.ajax({
                        url: '/commerce/ajax/credit-cards/remove-orphaned-cards/',
                        method: 'POST'
                    });
                }

                // Track confirmation error
                ga('send', 'event', 'Commerce', 'Payment confirmation (3D secure)', 'Error: ' + result.error.message);

                // Display the error
                alert(result.error.message);
                window.location.replace("/commerce/checkout");
            } else {
                callback(result);

                // Track confirmation success
                ga('send', 'event', 'Commerce', 'Payment confirmation (3D secure)', 'Confirmed');
            }
        });
    };


    // Make any element a link (e.g. a table row)
    $(document).on('click', '[data-link]', function(e) {
        var target = $(e.target);

        // Skip if clicked on a link or button
        if (target.is('a') || target.is('button')) {
            return;
        }

        // Skip if selecting
        if (getSelection().toString()) {
            return;
        }

        var url = $(this).data('link');

        if( e.ctrlKey || e.metaKey ) {
            // Open in new tab
            window.open(url, '_blank');
        } else {
            location.href = url;
        }
    });


    // Format JSON for display
    $.fn.formatJSON = function() {
        return this.each(function(index, item) {
            var element = $(item);
            var value = $.trim(element.val());

            if (value) {
                element.val(JSON.stringify(JSON.parse(value), undefined, 4));
            }
        });
    };


    // Format the JSON textareas
    $('.pretty-json').formatJSON();


    // Settings toggles
    $(document).on('change', '[data-setting]', function() {
        var element = $(this);
        var setting = element.data('setting');
        var enabled = element.is(':checked');

        if (enabled) {
            Cookies.set(setting, 'true');
        } else {
            Cookies.remove(setting);
        }
    });


    // Load element contents async
    $(document).find('[data-load]').each(function() {
        var element = $(this);
        var url = element.data('load');
        var trigger = element.data('trigger');
        var method = element.data('method');
        var load = function() {
            $.get(url, function(response) {
                if (method == 'replace') {
                    element.replaceWith(response);
                } else {
                    element.html(response);
                }

                // Emmit a success event
                $(document).trigger('load-success', {
                    element: element,
                    url: url
                });

                // Ensure tooltips
                $('[data-toggle="tooltip"]').tooltip();
            });
        };

        switch (trigger) {
            case 'click':
                element.on('click', load);
                break;
            case 'scroll':
                // TODO
                break;
            default:
                load();
        }
    });


    // Screened profile badge with popover
    $(document).on('mouseenter', '.screened-popover', function() {
        var element = $(this);

        element.popover({
            html: true,
            sanitize: false,
            trigger: 'manual',
            placement: 'bottom',
            content: function() {
                return element.siblings('template').html();
            }
        }).popover('show');

        $(document).one('click', function() {
            element.popover('hide');
        });
    });

    // Focus search field when forward slash is pressed.
    /*
    $(document).keydown(function(e) {
        // / (forward slash) key = search
        if(e.keyCode===55) {
            $("#search-input").focus();
            $(".search-form").addClass('focus');
            e.preventDefault();
            return;
        }
    });
    */
});


// Loading backdrop, can be applied to any container element
// Example usage:
// $('body').loading(true, { title: "Holding the door", description: "Please wait a second...", animation: false });
// $('body').loading(false);

$(function() {
    $.fn.loading = function(toggle, options) {
        $('.loading-overlay').remove();

        if( ! toggle ) {
            return;
        }

        var defaults = {
            'animation': true,
            'title': 'Loading, please wait...'
        };

        var settings = $.extend(defaults, options);
        var html = templates.render('partials/_loading.html', settings);

        this.append(html);

        // Hide all tooltips
        $('.tooltip').tooltip('hide');
    };
});


$(function() {
    // Requires login
    $(document).on('click', '[data-requires="user"]', function(e) {
        var element = $(this);

        if( typeof USER == 'undefined' ) {
            e.preventDefault();
            e.stopImmediatePropagation();


            var url = element.attr('href');
            var data = (url) ? {redirect_to: url} : null;
            // Render signup template in modal
            $.ajax({
                url: '/user/signup',
                data: data,
                success: function(html) {
                    $('.modal').modal('hide');
                    $(html).modal('show');
                }
            });
        }
    });


    // Submit the modal login form on enter
    $(document).on('keydown', '#login-modal #login-form', function(e) {
        if( e.keyCode == 13 ) {
            $(this).submit();
        }
    });


    // Login form success
    $(document).on('form.submit.success', '#login-form', function(e) {
        // Track the event
        ga('send', 'event', 'User', 'Log in', 'Logged in successfully');

        // Track page view
        ga('send', 'pageview', '/user/login/success');
    });


    // Login form errors
    $(document).on('form.submit.error', '#login-form', function(e, data) {
        var form = $(this);

        // Track the event
        ga('send', 'event', 'User', 'Log in', 'Error');

        form.parents('.modal-content').loading(false);

        // Recaptcha required
        if( data && data.errors && 'g-recaptcha-response' in data.errors ) {
            form.recaptcha(data);
            e.stopImmediatePropagation();
        }
    });


    // Signup form success
    $(document).on('form.submit.success', '#signup-form', function(e) {
        // Track the event
        ga('send', 'event', 'User', 'Sign up', 'Signed up successfully');

        // Track page view
        ga('send', 'pageview', '/user/signup/success');
    });


    // Signup form error
    $(document).on('form.submit.error', '#signup-form', function(e, data) {
        var form = $(this);
        form.parents('.modal-content').loading(false);

        // Track the event
        ga('send', 'event', 'User', 'Sign up', 'Error');

        // Recaptcha required
        if( data && data.errors && 'g-recaptcha-response' in data.errors ) {
            form.recaptcha(data);
            e.stopImmediatePropagation();
        }
    });


    // Load the Facebook SDK on demand
    $.loadFacebookSDK = function(callback) {
        callback = callback || $.noop();

        $.getScript('https://connect.facebook.net/en_US/sdk.js', function() {
            FB.init({
                appId: window.SOCIAL_AUTH_FACEBOOK_KEY,
                xfbml: true,
                version: 'v2.8'
            });

            callback();
        });
    };


    // Connect with Facebook
    $(document).on('click', '[data-toggle="facebook"]', function() {
        var element = $(this);
        var form = element.parents('form');

        form.attr('action', window.SOCIAL_AUTH_FACEBOOK_URL);

        $.loadFacebookSDK(function() {
            FB.login(function(response) {
                if( response.authResponse && 'accessToken' in response.authResponse ) {
                    $('<input />', {
                        name: 'access_token',
                        value: response.authResponse.accessToken
                    }).appendTo(form);

                    $('<input />', {
                        name: 'csrfmiddlewaretoken',
                        value: Cookies.get('csrftoken')
                    }).appendTo(form);

                    form.submit();
                } else {
                    ga('send', 'event', 'User', 'Connect with Facebook', 'Cancelled');
                }
            }, {
                scope: 'public_profile,email'
            });
        });
    });
});


$(function() {
    // Validate the email address
    $(document).on('blur', '.email-validation input', function(e) {
        var element = $(this);
        var value = $.trim(element.val());
        var parent = element.parents('.custom-validation');
        var api_key = window.DEBOUNCE_PUBLIC_KEY;

        if (value.length > 0 && api_key) {
            parent.addClass('loading').removeClass('success warning');

            $('#email-did-you-mean').addClass('d-none');

            $.ajax({
                url: 'https://api.debounce.io/v1/',
                data: {
                    api: api_key,
                    email: value,
                    gsuite: true
                },
                success: function(response) {
                    if (response && 'debounce' in response && 'code' in response.debounce) {
                        // Debounce response codes:
                        // https://debounce.io/resources/help-desk/understanding-results/result-codes/
                        if (response.debounce.code == 5) {
                            // Mark as valid
                            parent.addClass('success');
                        } else if ('did_you_mean' in response.debounce && response.debounce.did_you_mean.length > 0) {
                            // "Did you mean?" suggestion
                            var suggestion = response.debounce.did_you_mean;

                            $('#email-did-you-mean').removeClass('d-none').find('span').text(suggestion);

                            $('#email-did-you-mean a').on('click', function(e) {
                                e.preventDefault();
                                element.val(suggestion);
                                $('#email-did-you-mean').addClass('d-none');
                            });
                        } else if (response.debounce.code in [1, 6]) {
                            // Mark as warning
                            // 1 = Not an email
                            // 6 = Verified as invalid (Bounce)
                            parent.addClass('warning');
                        }
                    }
                },
                complete: function() {
                    parent.removeClass('loading');
                }
            });
        }
    });
});

// Utility library to expose common AJAX functionality to HTML forms

$(function() {
    // Recaptcha widget
    // Example usage: $(form).recaptcha();
    // The form should have a div#recaptcha-holder where you want the widget to appear
    $.fn.recaptcha = function() {
        var form = this;
        var placeholder = form.find('#recaptcha-holder');
        var html = templates.render('partials/_recaptcha.html', {
            recaptcha_public_key: window.RECAPTCHA_PUBLIC_KEY
        });

        placeholder.html(html);

        form.trigger('recaptcha-attached');
    };


    // Form validation
    // Add class "needs-validation" to the <form> element
    // It will validate according to the input properties such as "required", type="number", etc.
    $(document).on('submit', 'form.needs-validation', function(e) {
        var form = e.currentTarget;
        var valid = form.checkValidity();

        $(form).addClass('was-validated');
        $(form).removeClass('server-validated');
        $(form).find('.invalid-feedback.added').remove();

        if( ! valid ) {
            e.preventDefault();
            e.stopImmediatePropagation();
        }
    });


    // Submit form through another element
    $(document).on('click', '[data-toggle="submit"]', function() {
        var element = $(this);
        var form = $(element.data('target'));

        form.submit();
    });


    // AJAX forms
    $(document).on('submit', 'form[data-submit="ajax"]', function(e) {
        e.preventDefault();

        var form = $(this);

        $.ajax({
            url: form.attr('action'),
            method: form.attr('method'),
            data: form.serializeObject(),
            success: function(data) {
                form.trigger('form.submit.success', data);
            },
            error: function(data) {
                form.trigger('form.submit.error', data.responseJSON);
            },
            complete: function(data) {
                form.trigger('form.submit.complete', data.responseJSON);
            }
        });
    });


    // AJAX form success callback
    $(document).on('form.submit.success', 'form[data-submit="ajax"]', function(e, response) {
        var form = $(this);
        var notification = {
            type: 'success',
            title: form.data('success-title'),
            message: form.data('success-message')
        };

        // Redirect if the response contains a redirect_url
        if (typeof response == 'object' && 'redirect_url' in response) {
            location.href = response.redirect_url;
            return;
        }

        switch (form.data('success')) {
            // Reload on success
            case 'reload':
                Cookies.set('flash-message', notification);
                location.reload();
                break;

            // Redirect on success
            case 'redirect':
                Cookies.set('flash-message', notification);
                location.replace(form.data('success-url'));
                break;

            // Just notify
            default:
                $.notify(notification);
        }
    });


    // AJAX form error callback
    $(document).on('form.submit.error', 'form[data-submit="ajax"]', function(e, data) {
        var form = $(this);
        var notification = {
            type: 'danger',
            title: form.data('error-title') || "Something went wrong",
            message: form.data('error-message') || "Please contact support@iconfinder.com if the problem continues."
        };

        if( data ) {
            if( 'message' in data ) {
                notification.message = data.message;
            }

            if( 'errors' in data ) {
                form.removeClass('was-validated').addClass('server-validated');

                $.each(data.errors, function(key, value) {
                    var element = form.find('[name='+ key +']');

                    element.parent().find('.invalid-feedback.added').remove();
                    element.addClass('is-invalid').after('<div class="invalid-feedback added">'+ value +'</div>');
                });
            }
        }

        $.notify(notification);
    });


    // Auto-submit a form when an element (e.g. select) is changed
    $(document).on('change', '[data-autosubmit="true"]', function() {
        $(this).parents('form').submit();
    });
});


// Utility library to expose common AJAX functionality to HTML data properties
// Example usage:
// <button type="button" data-remote="ENDPOINT_URL" data-method="post" data-id="1" data-name="Hodor" data-success="reload">Button label</button>

$(function() {
    // Click event on the element
    $(document).on('click', '[data-remote]', function(e) {
        e.preventDefault();

        var element = $(this);
        var data = element.data();
        var parent = $('body');

        // Reserved data properties that won't be sent as AJAX data
        var strip_data = [
            'modal',
            'toggle',
            'remote',
            'method',
            'confirm',
            'loadingTitle',
            'success',
            'successTitle',
            'successMessage',
            'error',
            'errorTitle',
            'errorMessage',
            'bs.tooltip',
            'tracking'
        ];

        // If the element is inside a modal, set the modal as parent
        if( element.parents('.modal-content').length ) {
            parent = element.parents('.modal-content');
        }

        // Check if user is trying to open a remote URL in a new tab
        var href = element.attr('href');
        if ((e.metaKey || e.ctrlKey) && !(href == undefined || href == '#')) {
            // Don't add loading delays if remote URLs are opened in a new tab
            var loading_delay = null;
        } else {
            // Omit the loading overlay for fast calls
            var loading_delay = setTimeout(function() {
                parent.loading(true, {
                    title: data.loadingTitle
                });
            }, 200);
        }


        $.ajax({
            url: data.remote,
            method: data.method || "GET",
            data: _.omit(data, strip_data),
            success: function(response) {
                element.trigger('remote.call.success', response);
            },
            error: function(response) {
                element.trigger('remote.call.error', response);
            },
            complete: function() {
                if (loading_delay) {
                    clearTimeout(loading_delay);
                }
                parent.loading(false);
            }
        });
    });


    // Remote call success callback
    $(document).on('remote.call.success', '[data-remote]', function(e, response) {
        var element = $(this);
        var data = element.data();
        var notification = {
            type: 'success',
            title: data.successTitle,
            message: response.message || data.successMessage
        };

        // Redirect if the response contains a redirect_url
        if (typeof response == 'object' && 'redirect_url' in response) {
            location.href = response.redirect_url;
            return;
        }

        if( data.success )  {
            if( data.success == 'dismiss' ) {
                $.notify(notification);
            } else {
                // Set a flash message in a cookie
                Cookies.set('flash-message', notification);

                if( data.success == 'reload' ) {
                    location.reload();
                } else {
                    location.replace(data.success);
                }
            }
        }
    });


    // Remote call error callback
    $(document).on('remote.call.error', '[data-remote]', function(e, response) {
        var element = $(this);
        var data = element.data();

        if( "responseJSON" in response ) {
            response = response.responseJSON;
        }

        var notification = {
            type: 'danger',
            title: data.errorTitle,
            message: response.message || data.errorMessage || "Failed loading remote data"
        };

        // Hide loading overlay
        $(this).parents().has('.loading-overlay').loading(false);

        $.notify(notification);
    });
});


$(function() {
    // Sticky navigation
    $('.navbar:not(.navbar-static)').each(function() {
        var treshold = $(this).offset().top;
        var header = $('[data-toggle="navigation-sticky"]');

        if( header.length ) {
            treshold = header.offset().top + header.outerHeight();
            $('body').addClass('nav-fade');
        }

        $(window).on('load scroll', function() {
            $('body').toggleClass('nav-fixed', $(window).scrollTop() >= treshold);
        });
    });
});


// Library to work with modals
$(function() {
    // Runs when a modal is shown
    $(document).on('shown.bs.modal', '.modal', function() {
        var modal = $(this);

        modal.find('[autofocus]').trigger('focus');
        modal.find('[data-toggle="tooltip"]').tooltip();
        modal.find('[data-toggle="popover"]').popover();
    });


    // Tracking modal events
    $(document).on('shown.bs.modal', '.modal', function() {
        var modal = $(this);
        var title = modal.find('#modal-title').text();

        // Track pageview when modals are opened
        ga('send', 'pageview', '/modal/' + title);

        // Track modal confirmation events
        modal.on('click', '[data-action="confirm"]', function() {
            ga('send', 'event', 'Modal', title, 'Confirm');
        });

        // Track modal cancel/close events
        modal.on('click', '[data-dismiss="modal"]', function() {
            ga('send', 'event', 'Modal', title, 'Cancel');
        });
    });


    // Destroy modal on close
    $(document).on('hidden.bs.modal', '.modal.rendered', function() {
        $(this).remove();
    });


    // Reload page on modal dismiss
    $(document).on('hide.bs.modal', '.modal[data-dismiss="reload"]', function() {
        location.reload();
    });


    // Display modal form loading
    $(document).on('submit', '.modal [data-submit="ajax"]', function(e) {
        var form = $(this);

        form.parents('.modal-content').loading(true, {
            title: form.data('loading-title'),
            description: form.data('loading-description')
        });
    });


    // Hide modal form loading on error
    $(document).on('form.submit.error', '.modal [data-submit="ajax"]', function(e) {
       var form = $(this);

       form.parents('.modal-content').loading(false);
    });


    // Hide modal form loading
    $(document).on('form.submit.complete', '.modal [data-submit="ajax"]', function() {
        var data = $(this).data();

        if( data.success != 'reload' ) {
            $(this).find('.modal-content').loading(false);
        }
    });


    // Dismiss modal on success
    $(document).on('form.submit.success remote.call.success', '.modal [data-success="dismiss"]', function() {
        $(this).parents('.modal').modal('hide');
    });


    // Render modals
    $(document).on('click', '[data-modal]', function(e) {
        e.preventDefault();

        // If link is opened in a new tab, and it has a valid href, don't open modal
        var href = $(this).attr('href');
        if ((e.metaKey || e.ctrlKey) && !(href == undefined || href == '#')) {
            window.open(href);
            return;
        }

        // Otherwise continue with a modal
        var data = $(this).data();

        template = data["modal"].split("?")[0];

        if( data.remote ) {
            // Remote data
            $(this).one('remote.call.success', function(e, response) {
                if (data.modal == 'remote') {
                    // Server-rendered modals
                    $('.modal').modal('hide');
                    $(response).modal('show');
                } else {
                    // JS-rendered modals with remote data
                    renderModal(template, $.extend({}, data, response));
                }
            });
        } else {
            // Modal with local data
            renderModal(template, data);
        }
    });


    // Render modal
    window.renderModal = function(template, data) {
        var modal = templates.render(template + '.html', data);

        $('.modal').modal('hide');
        $(modal).modal('show');

        $(document).trigger('modal.render', {
            modal: template
        });
    };

    if(window.location.search) {
        data = {};
        query = window.location.search.substring(1).split('&');

        for(var i=0; i<query.length; i++) {
            key_value = query[i].split('=');
            data[key_value[0]] = key_value[1];
        }

        if(data["modal"] !== undefined) {
            template = data["modal"].replace("modal-", "").split("?")[0];

            if(data["remote"]) {
                $.ajax({
                    url: data["remote"],
                    method: "GET",
                    success: function(data) {
                        renderModal(template, data);
                    }
                });
            }
            else {
                renderModal(template, data);
            }
        }
    }
});



$(function() {
    // Update cart on add/remove
    $(document).on('remote.call.success', '[data-cart]', function(e, data) {
        e.preventDefault();

        if (!data) return;

        var counter = $('[data-counter="cart_items"]');
        var empty = data.cart.cart_items.length === 0;

        // Update counter
        counter.text(data.cart.cart_items.length);
        counter.parents('.nav-item').toggleClass('d-none', empty);

        // Show the cart, if not checkout page
        if( $(this).parents('.cart-preview').length === 0 ) {
            renderModal('cart/_modal', data);
        }

        $(document).trigger('cart.updated', data);
    });


    // Track pageview when the cart is opened
    $(document).on('shown.bs.modal', '#cart-modal', function() {
        ga('send', 'pageview', 'commerce/cart');
    });
});


$(function() {
    // Resize the icon details preview image
    $(document).on('show.bs.tab', '[data-toggle="tab"][href^="#png-"]', function() {
        var element = $(this);
        var size = element.attr('href').replace('#png-', '');
        var previewWidth = $('#preview-image').width();

        // Ensure the preview size does not exceed the window width
        if (size > previewWidth) {
            size = previewWidth;
        }

        $('#preview-image img').width(size).height(size);
    });


    // Ensure "other" format is selected
    $(document).on('show.bs.tab', '[data-toggle="tab"][href^="#other"]', function() {
        var formats = $('#other [data-toggle="tab"]');

        if( ! formats.find('.active').length ) {
            formats.first().tab('show');
        }
    });


    // Track opening icons in the Editor
    $(document).on('click', '[data-action="editor"]', function() {
        Intercom('trackEvent', 'icon-opened-in-editor');
    });


    // Add the current URL to the reporting form
    $(document).on('shown.bs.modal', '#flag-modal', function() {
        $('#flag-icon-form [name="url"]').val(window.location.href);
    });


    // Track reporting of icons
    $(document).on('submit', '#flag-icon-form', function() {
        ga('send', 'event', 'Icons', 'Flag', 'Icon flagged');

        Intercom('trackEvent', 'icon-reported');
    });


    // Icon formats popover
    $(document).on('mouseenter', '.formats-help-link', function() {
        var element = $(this);
        var content = element.siblings('.formats-help-template').html();

        element.popover({
            content: content,
            trigger: 'click',
            html: true,
            sanitize: false
        });
    });

    // Icon formats popover prevent default click
    $(document).on('click', '.formats-help-link', function(e) {
        e.preventDefault();
    });
});


$(function () {
    // Generate the download and check URLs
    var download_url = function (data, check) {
        var action = check ? 'check-download' : 'download';
        var url = ['', data.assetType + 's', data.assetId, action, data.assetFormat];

        if (!check && 'size' in data) {
            url.push(data.size);
        }

        var url_string = url.join('/');

        // Append the stored_data_id if passed
        if (!check && 'stored_data_id' in data && data.stored_data_id != 'original') {
            url_string += '?stored_data_id=' + data.stored_data_id;
        }

        return url_string;
    };


    var fetchData = function() {
        // Wrapper on fetch to throw errors on status not 2xx
        return fetch.apply(null, arguments).then(function(response) {
            if (!response.ok) {
                var err = new Error("HTTP status code: " + response.status);
                err.response = response;
                err.status = response.status;
                throw err;
            }
            return response;
        })
    };


    var copyToClipboard = function(data, format) {
        var success_msg = "The file was successfully copied to the clipboard.";

        if (navigator.userAgent.match(/safari/i) && !navigator.userAgent.match(/chrome|chromium|crios/i)) { // Chrome on Mac/iOS has Safari in user-agent
            // Safari
            // Due to security restrictions in Safari, you're not allowed to do something like this:
            // var item = ClipboardItem(<args>);
            // navigator.clipboard.write(item);
            // All the logic of what data is in the ClipboardItem as well as what is written to navigator.clipboard, has to happen within a single Promise
            return new Promise(function(resolve, reject) {
                if (format == 'svg') {
                    navigator.clipboard.write([
                        new ClipboardItem({
                           "text/plain": new Promise(function(_resolve, _reject) {
                                fetchData(download_url(data))
                                .then(function(response){
                                    response.text()
                                    .then(function(text) { _resolve(text); })
                                    .catch(function(err) { _reject(err); })
                                })
                                .catch(function(err) { _reject(err); })
                            })
                        })
                    ])
                    .then(function(){ resolve(success_msg); })
                    .catch(function(err) { reject(err); })
                } else {
                    navigator.clipboard.write([
                        new ClipboardItem({
                           "image/png": new Promise(function(_resolve, _reject) {
                                fetchData(download_url(data))
                                .then(function(response){
                                    response.blob()
                                    .then(function(blob) { _resolve(blob); })
                                    .catch(function(err) { _reject(err); })
                                })
                                .catch(function(err) { _reject(err); })
                            })
                        })
                    ])
                    .then(function(){ resolve(success_msg); })
                    .catch(function(err) { reject(err); })
                }
            })
        } else {
            // Other browsers
            return new Promise(function(resolve, reject) {
                fetchData(download_url(data))
                .then(function(response){
                    if (format == 'svg') {
                        response.text()
                        .then(function(text){
                            navigator.clipboard.writeText(text)
                            .then(function() { resolve(success_msg); })
                            .catch(function(err) { reject(err); });
                            })
                        .catch(function(err) { reject(err); })
                    }
                    else {
                        // Firefox note:
                        // This does not work by default. As of date, Firefox does not officially support ClipboardItem
                        // To get this to work in Firefox, the user must change the setting:
                        // 'dom.events.asyncClipboard.clipboardItem': true
                        // in about:config
                        // Read more: https://www.iconfinder.com/en/articles/6151476-copy-to-clipboard
                        response.blob()
                        .then(function(blob){
                            navigator.clipboard.write([new ClipboardItem({"image/png": blob})])
                            .then(function() { resolve(success_msg); })
                            .catch(function(err) { reject(err); });
                        })
                        .catch(function(err){ reject(err); })
                    }
                })
                .catch(function(err){ reject(err); })
            })
        }

    }


    // Update the Pro credits counter
    var updateCredits = function () {
        var creditsData = $('#nav-credits-counter').data();
        if (!creditsData || typeof(creditsData.credits_count) != 'number')
            return

        $.ajax({
            url: '/partials/pro-counter',
            success: function(html) {
                $('#nav-credits-counter').replaceWith(html);
                $('#nav-credits-counter').tooltip();
            }
        });
    };


    var getBase64Asset = function (data) {
        if (data.assetFormat == 'svg') {
            $.ajax({
                url: download_url(data),
                dataType: 'text',
                success: function (response) {
                    render('data:image/svg+xml;base64,' + Base64.encode(response));
                    $(document).trigger('register-download', data);
                }
            });
        } else {
            // Raster formats can be handled by rendering the image
            // to a canvas and then getting the data URL using the
            // HTML 5 canvas API.
            var img = new Image();
            img.src = download_url(data);
            img.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.width = this.width;
                canvas.height = this.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(this, 0, 0);

                render(canvas.toDataURL("image/" + data.assetFormat));
                $(document).trigger('register-download', data);
            };
        }

        var render = function (datauri) {
            renderModal('icons/_base64', {
                format: data.assetFormat.toUpperCase(),
                datauri: datauri
            });

            $('body').loading(false);
        };
    };


    var copyAsset = function(data) {
        var err_notify = {
            type: 'danger',
            message: "The file could not be copied to the clipboard.",
            position: 'top center'
        };

        var err_notify_firefox = {
            type: 'danger',
            title: 'Copying disabled',
            message: 'Learn how to enable it in Firefox <a href="https://www.iconfinder.com/en/articles/6151476-copy-to-clipboard">here</a>.',
            position: 'top center',
            delay: 6000
        };

        var success_notify = {
            type: 'success',
            message: 'Copied ' + data.assetFormat.toUpperCase() + ' ' + data.assetType,
            icon: 'copy',
            position: 'top center'
        };
        
        var element = data.downloadTarget;

        // UI State functions
        var loadState = function(){
            // Change UI into load state
            $(element).find(".copytext").addClass('d-none');
            $(element).find(".copytext-load").removeClass('d-none');
            $(element).findIncludeRoot(".btn-copy").removeClass('btn-outline-dark').addClass('btn-loading');
        };

        var successState = function() {
            $(element).find(".copytext-load").addClass('d-none');
            $(element).find(".copytext-success").removeClass('d-none');
            $(element).findIncludeRoot(".btn-copy").removeClass('btn-loading btn-outline-dark').addClass('btn-green-light');
            $(document).trigger('register-download', data);
            updateCredits();
            $.notify(success_notify);
        };

        var errorState = function(err) {
            $(element).find(".copytext-load").addClass('d-none');
            $(element).find(".copytext-error").removeClass('d-none');
            $(element).findIncludeRoot(".btn-copy").removeClass('btn-loading btn-outline-dark').addClass('btn-danger');
            if ('message' in err && err.message == 'Document is not focused.') {
                err_notify.message = "Copy failed. When copying, keep the browser window active until you see a success message.";
                err_notify.autohide = 'False';
                $.notify(err_notify);
            }
            else if (navigator.userAgent.match(/firefox/i))
                $.notify(err_notify_firefox);
            else
                $.notify(err_notify);
        };

        var resetUI = function() {
            setTimeout(function() {
                $(element).find(".copytext").removeClass('d-none');
                $(element).find(".copytext-load").addClass('d-none');
                $(element).find(".copytext-success").addClass('d-none');
                $(element).find(".copytext-error").addClass('d-none');
                $(element).findIncludeRoot(".btn-copy").removeClass('btn-loading btn-danger btn-green-light').addClass('btn-outline-dark');
            }, 1500); // Reset classes after a second
        };

        // Copy logic
        loadState();
        copyToClipboard(data, data.assetFormat)
        .then(function(result) { successState(); })
        .catch(function(err) { errorState(err); })
        .finally(function() { resetUI(); })
    }


    var downloadAsset = function (data) {
        // Downloads an asset with an initial permission check
        $.ajax({
            url: download_url(data, true),
            success: function (response) {
                var notification = {
                    type: 'success',
                    message: 'Downloading ' + (response.is_premium ? 'premium' : 'free') + ' ' + data.assetFormat.toUpperCase() + ' ' + data.assetType,
                    icon: 'download',
                    position: 'top center'
                };
                location.href = download_url(data)
                updateCredits();
                $.notify(notification);
                $(document).trigger('register-download', data);
            },
            error: function (response, status, xhr) {
                var code = '';

                var err_notify = {
                        type: 'danger',
                        message: 'The file could not be downloaded.',
                };

                if (response.responseJSON && response.responseJSON.code) {
                    code = response.responseJSON.code;
                }

                if (code == 'no_more_downloads_available') {
                    $(document).trigger('pro.upgrade.prompt', response.responseJSON);
                } else if (code == 'download_limit_reached') {
                    renderModal('limiter/_limit-reached', response.responseJSON);

                    ga('send', 'event', 'User', 'Premium download limit', 'Limit reached');
                } else if (code == 'registration_required') {
                    renderModal('limiter/_signup-prompt');

                    ga('send', 'event', 'User', 'Free download limit', 'Limit reached');
                } else if (code == 'pro_subscription_required') {
                    renderModal('limiter/_subscription-required');

                    ga('send', 'event', 'User', 'Pro subscription required', data.assetFormat);
                } else {
                    $.notify(err_notify);
                }
            }
        });
    };


    // The download event
    $(document).on('download', function (e, options) {
        var element = options.downloadTarget;

        if (!element) return;

        var data = $.extend({
            assetType: 'icon',
            assetId: element.data('asset-id'),
            assetFormat: Cookies.get('last_download_format') || 'png',
            size: Cookies.get('last_download_size') || 256
        }, options);

        if (data.base64)
            getBase64Asset(data);
        else if (data.copydata)
            copyAsset(data);
        else
            downloadAsset(data);
    });


    // Register asset action
    $(document).on('register-download', function (e, data) {
        // Remember the download format
        if ('assetFormat' in data) {
            Cookies.set('last_download_format', data.assetFormat);
        }

        // Remember the download size
        if ('size' in data) {
            Cookies.set('last_download_size', data.size);
        }

        // Send the tracking events
        if (data.copydata) {
            Intercom('trackEvent', 'icon-copy');
            ga('send', 'event', filters.title(data.assetType), 'Copy to clipboard', data.assetFormat.toLowerCase());
        } else {
            Intercom('trackEvent', 'icon-download');
            ga('send', 'event', filters.title(data.assetType), 'Download', data.assetFormat.toLowerCase());
        }
    });


    // Register ZIP downloads
    $(document).on('click', '#download-zip-modal [data-params]', function () {
        var params = $(this).data('params').split('|');
        var data = {
            assetType: params[0],
            assetFormat: params[1]
        };

        // If size is specified
        if (params[2] !== 'None') {
            data.size = params[2];
        }

        $(document).trigger('register-download', data);
        updateCredits();

        var notification = {
            type: 'success',
            message: 'Downloading zip file.',
            icon: 'download'
        };

        // Hide the modal
        $('#download-zip-modal').modal('hide');

        // Show success notification
        $.notify(notification);
    });


    // Bind the download buttons
    $(document).on('click', '[data-action="download"]', function (e) {
        e.preventDefault();

        var element = $(this);
        var data = element.data();

        data.downloadTarget = element;

        $(document).trigger('download', data);
    });


    // SHORTCUT KEYS

    var activejQueryElement = function(selector) {
        /**
         * Return Jquery object with a single element. As oppose to HTML elements.
         */
        var element = $(selector);
        if (element && element.length == 1)
            return element
        return null
    }

    // When the mouse is placed inside an element on page load, we do an extra check which
    // is not covered by mouseenter
    var activeOnLoad = activejQueryElement('.shortcut-keys-active');
    if (activeOnLoad) {
        window.shortcutsActive = true;
        activeOnLoad.addClass('shortcut-action');
    }

    // Elements that support shortcut keys
    $(document).on('mouseenter', '.shortcut-keys-active', function() {
        window.shortcutsActive = true;
        $(this).addClass('shortcut-action');
    }).on('mouseleave', '.shortcut-keys-active', function() {
        window.shortcutsActive = false;
        $(this).removeClass('shortcut-action');
    });

    var formatData = function(selector) {
        var element = activejQueryElement(selector); // Make sure we're not acting on more than one element
        var data = null;
        if (element) {
            data = element.data();
            data.downloadTarget = element;

            if (editor.current_version_id)
                data.stored_data_id = editor.current_version_id;

            return data;
        }

        return null;
    }

    // Key map checkers
    var noSpecialKeysPressed = function(e) { return !(e.metaKey || e.ctrlKey || e.shiftKey || e.altKey); };
    var altKeyAllowed = function(e) { return !(e.metaKey || e.ctrlKey || e.shiftKey); };
    var copyKeyRequired = function(e) { return ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && !e.shiftKey); };

    // Mapped keys
    $(document).on('keydown', function(event){
        // Check if search is in focus.
        if (document.activeElement.tagName == 'INPUT')
            return

        // Check if cursor is within a hotkey enabled screen region.
        if (!window.shortcutsActive)
            return

        data = formatData('.shortcut-action');
        if (!data)
            return

        if (noSpecialKeysPressed(event)) {
            if (event.code === "KeyD") {
                $(document).trigger('download', data);
                highlightElement(data);
            }
            else if (event.code === "KeyF") {
                var last_download_format = Cookies.get('last_download_format') || 'png';
                var swapped_format = (last_download_format == 'png') ? 'svg' : 'png';
                var osMsg = window.navigator.platform.match("Mac") ? '&#8984; + C' : 'Ctrl + C';
                var codeStyle = (swapped_format == 'png') ? '' : 'class="text-danger"'; // Use the common style for PNGs

                $('[data-toggle="tab"][href="#'+ swapped_format +'"]').trigger('click');

                var notification = {
                    type: 'info',
                    message: 'Format changed to <code ' + codeStyle + '>' + swapped_format.toUpperCase() + '</code>',
                    position: 'bottom center'
                }

                $.notify(notification);

                Cookies.set('last_download_format', swapped_format);
            }
        }

        if (altKeyAllowed(event)) {
            if (event.code === "KeyS") {
                data.copydata = event.altKey;
                data.assetFormat = 'svg';
                $(document).trigger('download', data);
                highlightElement(data);
            }
            else if (event.code === "KeyP") {
                data.copydata = event.altKey;
                data.assetFormat = 'png';
                $(document).trigger('download', data);
                highlightElement(data);
            }
        }

        if (event.code === "KeyC" && copyKeyRequired(event)) {
            event.preventDefault();
            data.copydata = true;
            $(document).trigger('download', data);
            highlightElement(data);
        }
    });


    // Highlight the icon preview
    var highlightElement = function(data) {
        var element = data.downloadTarget;
        if (element && element.is('.icon-preview')) {
            element.addClass('highlight');

            setTimeout(function () {
                element.removeClass('highlight');
            }, 500);
        }
    }
});


$(function() {
    // Toggle custom input
    $('#prepayment-form').on('change', '[name="predefined"]', function() {
        var element = $(this);
        var amount = element.val();
        var form = element.parents('form');
        var custom = $('#custom-amount');

        if( amount === '' ) {
            custom.collapse('show');
        } else {
            custom.collapse('hide');
        }

        form.removeClass('was-validated');

        form.find('input[name="amount"]').val(amount);
    });


    // Form submit
    $('#prepayment-form').on('submit', function() {
        var form = $(this);

        // Convert the prepayment amount to cents before submitting
        var input = form.find('[name="amount"]');
        var amount = parseFloat(input.val()).toFixed(2);
        var cents = amount * 100;

        form.find('[name="amount_cents"]').val(cents);

        // Track deposit event
        ga('send', 'event', 'Commerce', 'Make prepayment', 'Amount', amount);
    });
});


// Profile popup cards
$(function() {
    $(document).on('mouseenter', '[data-profile]', function() {
        var element = $(this);
        var username = element.data('profile');

        // Skip on small screens
        if( $(window).width() < 600 ) return;

        var timer = setTimeout(function() {
            // Fetch the profile data
            $.get('/profiles/ajax/'+ username +'/summary', function(response) {
                element.popover({
                    trigger: 'manual',
                    html: true,
                    sanitize: false,
                    content: $(response)[0].innerHTML,
                    template: '<div class="popover popover-profile" style="width: 100%;"><div class="arrow"></div><div class="popover-body p-0"></div></div>'
                }).popover('show');

                // Mark the popup as focused
                $('.popover-profile').on('mouseenter', function() {
                    $(this).addClass('focus');
                });

                // Destroy a focused popup on mouse leave
                $('.popover-profile').on('mouseleave', function() {
                    element.popover('dispose');
                });

                // Destroy the popup if a modal is rendered
                $(document).on('modal.render', function() {
                    element.popover('dispose');
                });

                // Destroy the popup on unhovering
                element.on('mouseleave', function() {
                    // Allow time for the card to be focused
                    setTimeout(function() {
                        if( ! $('.popover-profile').hasClass('focus') ) {
                            element.popover('dispose');
                        }
                    }, 200);
                });
            });
        }, 500);

        element.on('mouseleave', function() {
            clearTimeout(timer);
        });
    });


    // Autofocus popup search input
    $(document).on('show.bs.popover', function() {
        $('.popover-profile [autofocus]').focus();
    });
});

        $(function() {
            $.fn.countdown = function() {
                var element = this;
                var date_end = element.data('countdown');

                if (!date_end) {
                    return;
                }

                date_end = date_end.split('+')[0];
                date_end = dayjs.utc(date_end);

                setInterval(function() {
                    var date_now = dayjs.utc();
                    var diff = dayjs.utc(date_end.diff(date_now));
                    var difference = {
                        day: date_end.diff(date_now, 'day'),
                        hour: diff.format('HH'),
                        minute: diff.format('mm'),
                        second: diff.format('ss'),
                        total: date_end.diff(date_now, 'hour') + diff.format(':mm:ss')
                    };

                    $.each(difference, function(key, value) {
                        element.find('.' + key).text(value);
                    });
                }, 1000);
            };


            // Apply to available elements
            $('[data-countdown]').countdown();
        });


var editor = {
    init: function() {
        // TODO: Refactor this to load it from the backend based on icon ID or stored data ID.
        this.modal = $('#editor-modal');
        this.image = $('#editor-modal #svg-data');
        this.icon_id = this.image.data('id');

        this.has_edits = false;
        this.original_preview = $('#preview-image-' + this.icon_id + ' img').attr('src');
        this.original_svg = $('#editor-modal #svg-data').html();
        this.original_colors = $('#editor-modal .colors-list .color-group').map(function(index, element) {
            return $(element).data('originalColor');
        });
        this.current_version_id = 'original';
        this.colorpicker();
        this.versions.list();
        this.palettes.render();
        this.downloadPopover();
    },

    // Validate color hex format (#XXXXXX)
    validate_color: function(color) {
        return /^#[0-9A-F]{6}$/i.test(color);
    },

    // Map element original colors as data
    mapOriginalColors: function() {
        $.each(['fill', 'stroke', 'stop-color'], function(index, property) {
            editor.image.find('svg ['+ property +']').each(function() {
                var element = $(this);
                var value = element.attr(property).toLowerCase().replace('#', '-');

                if (value != 'none') {
                    element.addClass(property + value);
                }
            });
        });
    },

    // Update the list of colors when loading versions
    updateColors: function(colors) {
        var html = templates.render('recolor/_colors.html', {
            colors: colors
        });

        editor.modal.find('.colors-list').html(html);
        editor.colorpicker();
    },

    // Colorpicker
    colorpicker: function() {
        editor.mapOriginalColors();

        editor.modal.find('.minicolors-input-picker').each(function() {
            var element = $(this);
            var originalColor = element.data('originalColor');
            var input_field = element.parents('.tab-pane').find('.minicolors-input-field');

            element.minicolors({
                theme: 'bootstrap',
                inline: true,
                change: function(new_color) {
                    editor.has_edits = true;

                    $.each(['fill', 'stroke', 'stop-color'], function(index, property) {
                        var selector = 'svg .' + property + originalColor.toLowerCase().replace('#', '-');
                        editor.image.find(selector).attr(property, new_color);
                    });

                    editor.modal.find('.color-group.active').css('background-color', new_color);

                    input_field.val(new_color);

                    // Toggle the reset color button visibility
                    editor.modal.find('.tab-pane.active .reset-color').toggleClass('d-none', originalColor == new_color);
                }
            });
        });
    },

    versions: {
        // Fetch the list of versions
        list: function() {
            $.get('/stored_data/list/icon-' + editor.icon_id, function(response) {
                var versions = [{
                    id: 'original',
                    preview_url: editor.original_preview,
                    timepassed: 'Original'
                }];

                if ('stored_data_objects' in response && response.stored_data_objects.length > 0) {
                    $.merge(versions, response.stored_data_objects.slice(0, 5));
                }

                var html = templates.render('recolor/_versions.html', {
                    versions: versions
                });

                editor.modal.find('.versions-placeholder').html(html);
            });
        },

        // Load specific version data
        load: function(version_id) {
            editor.current_version_id = version_id;
            editor.has_edits = false;

            if (version_id == 'original') {
                editor.image.find('svg').replaceWith(editor.original_svg);
                editor.updateColors(editor.original_colors);
            } else {
                // editor.modal.loading(true);

                $.ajax({
                    url: '/stored_data/get/' + version_id,
                    success: function(response) {
                        editor.image.find('svg').replaceWith(response.data);
                        editor.updateColors(response.colors);
                        editor.modal.loading(false);
                    },
                    error: function() {
                        $.notify({
                            type: "error",
                            title: "Something went wrong",
                            message: "Could not load saved version"
                        });
                    }
                });
            }
        },

        // Save an edited version
        save: function() {
            // Skip saving if no edits
            if (!editor.has_edits) return;

            var svg = editor.image.find('svg').prop('outerHTML');
            var save_button = editor.modal.find('#btn-save-version');
            var save_button_label = save_button.text();

            save_button.text('Saving version...').attr('disabled', true);

            $.ajax({
                url: '/stored_data/save/icon-' + editor.icon_id,
                method: 'POST',
                data: {
                    svg_data: svg
                },
                success: function(response) {
                    editor.has_edits = false;
                    editor.current_version_id = response.stored_data_object.id;
                    editor.updateDownloadButtons();
                    editor.versions.list();
                },
                error: function() {
                    var msg = (Cookies.get('csrftoken')) ? "Could not save version" : "Could not save version. If you have configured your browser to disable cookies, please enable them for this site. It is used for security reasons.";
                    $.notify({
                        type: "danger",
                        message: msg,
                        delay: 6000
                    });
                },
                complete: function() {
                    save_button.text(save_button_label).removeAttr('disabled');
                }
            });
        }
    },

    // Saved color palettes
    palettes: {
        // Local storage key
        key: 'saved-colors',

        // List color palettes
        list: function() {
            var storage = localStorage.getItem(editor.palettes.key);

            if (storage) {
                return JSON.parse(storage);
            } else {
                return [];
            }
        },

        // Render the palettes UI
        render: function() {
            var html = templates.render('recolor/_palettes.html', {
                colors: editor.palettes.list()
            });

            editor.modal.find('.palettes-list').html(html);
            editor.modal.find('.palettes-list [data-toggle="tooltip"]').tooltip();
        },

        // Load a color from palette
        load: function(color) {
            if (!editor.validate_color(color)) return;

            editor.modal.find('.tab-pane.active .minicolors-input-field').val(color).trigger('change');
        },

        // Save a color to palette
        save: function(color) {
            if (!editor.validate_color(color)) return;

            var saved_colors = editor.palettes.list();

            // Add the new color in front of the list
            saved_colors.unshift(color);

            // Make the colors list unique
            var saved_colors_unique = saved_colors.filter(function(element, index, array) {
                return index === array.indexOf(element);
            });

            // Limit the number of colors to save
            var saved_colors_limited = saved_colors_unique.slice(0, 10);

            // Save to localStorage
            localStorage.setItem(editor.palettes.key, JSON.stringify(saved_colors_limited));

            editor.palettes.render();
        }
    },

    // Update the download buttons with the current version id
    updateDownloadButtons: function() {
        if (editor.current_version_id != 'original') {
            $('.popover .icon-download [data-action="download"]').data('stored_data_id', editor.current_version_id);
        }
    },

    // Download popover
    downloadPopover: function() {
        var html = $('#editor-download').html();
        var button = editor.modal.find('.download-button');

        button.popover({
            html: true,
            sanitize: false,
            content: html,
            placement: 'top'
        });

        // Update the download URLs
        button.on('shown.bs.popover', function() {
            editor.updateDownloadButtons();

            // Hide the popover when clicking outside
            $(document).on('click.download-popover', function(e) {
                if (!$(e.target).parents('.popover').length) {
                    button.popover('hide');
                    $(document).off('click.download-popover');
                }
            });

            // Update the popover position when toggling the tabs inside
            $('.popover .icon-download [data-toggle="tab"]').on('show.bs.tab', function() {
                button.popover('update');
            });
        });
    }
};


// Bind the events
$(function() {
    $(document).on('shown.bs.modal', '#editor-modal', function() {
        editor.init();
    });


    // Confirm closing with unsaved edits
    $(document).on('hide.bs.modal', '#editor-modal', function() {
        if (editor.has_edits) {
            return confirm('Are you sure you want to close without saving your changes?');
        }
    });


    // Save version
    $(document).on('click', '#editor-modal [data-action="save"]', editor.versions.save);


    // Load version
    $(document).on('click', '#editor-modal [data-version_id]', function() {
        // Save if there's been edits.
        if (editor.has_edits) {
            editor.versions.save();
        }

        var version_id = $(this).data('version_id');
        editor.versions.load(version_id);
    });


    // Update the color picker through the input field
    $(document).on('change', '#editor-modal .minicolors-input-field', function() {
        var element = $(this);
        var value = element.val();
        var colorpicker = element.parents('.tab-pane').find('.minicolors-input-picker');

        colorpicker.minicolors('value', { color: value });
    });


    // Ensure "other" format is selected
    $(document).on('show.bs.tab', '[data-toggle="tab"][href^="#other-modal"]', function() {
        var formats = $('#other-modal [data-toggle="tab"]');

        if( ! formats.find('.active').length ) {
            formats.first().tab('show');
        }
    });


    // Save color
    $(document).on('click', '[data-action="save-color"]', function() {
        var color = $('.tab-pane.active .minicolors-input-field').val();
        editor.palettes.save(color);
    });


    // Load color
    $(document).on('click', '[data-action="load-color"]', function(e) {
        e.preventDefault();

        var color = $(this).data('color');
        editor.palettes.load(color);
    });
});
